import request from '@/utils/request'

export function deviceList(data) {
  return request({
    url: '/message/device',
    method: 'post',
    data
  })
}
export function conversationList(params) {
  return request({
    url: '/message/conversation',
    method: 'post',
    data: params
  })
}
export function messageList(params) {
  return request({
    url: '/message/message',
    method: 'post',
    data: params
  })
}
export function getUploadUrl(data) {
  return request({
    url: '/system/oss/upload',
    method: 'post',
    data
  })
}

export function getUserInfo(params) {
  return request({
    url: '/system/ins/user/conversation/messageUserInfo',
    params: params
  })
}

export function send(data) {
  return request({
    url: '/message/send',
    method: 'post',
    data
  })
}