import { defineStore } from 'pinia'
import { ref } from 'vue'

const initialTag = [
  {
    fullPath: '/dashboard',
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      title: '首页'
    }
  }
]
export const useTagsStore = defineStore('tags', () => {
  const visitedTags = ref(JSON.parse(JSON.stringify(initialTag)))
  const cachedTags = ref(JSON.parse(JSON.stringify(initialTag)).map(v => v.fullPath))
  function addVisitedTags(route) {
    if (visitedTags.value.some(v => v.fullPath === route.fullPath)) return
    visitedTags.value.push(
      Object.assign({}, route)
    )
  }
  function deleteVisitedTags(route) {
    if (visitedTags.value.indexOf(route) !== -1) {
      visitedTags.value.splice(visitedTags.value.indexOf(route), 1)
    }
  }
  function clearVisitedTags() {
    visitedTags.value = JSON.parse(JSON.stringify(initialTag))
  }

  function addCachedTags(route) {
    if (cachedTags.value.includes(route.fullPath)) return
    cachedTags.value.push(route.fullPath)
  }
  function deleteCachedTags(route) {
    cachedTags.value.splice(cachedTags.value.indexOf(route.fullPath), 1)
  }
  function clearCachedTags() {
    cachedTags.value = []
  }
  return {
    visitedTags,
    addVisitedTags,
    deleteVisitedTags,
    clearVisitedTags,
    cachedTags,
    addCachedTags,
    deleteCachedTags,
    clearCachedTags
  }
})