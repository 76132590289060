// 消息类型
export const MESSAGE_TYPE = {
  Text: 'text',
  Image: 'image',
  Voice: 'audio',
  Video: 'video',
  File: 'file',
  Reply: 'reply'
}

export const baseUrl = process.env.VUE_APP_BASE_URL
export const headers = {
  Authorization: 'Bearer ' + localStorage.getItem('token')
}

/**
 * 生成唯一ID
 * @param prefix
 * @return {string}
 */
export function uuid(prefix = 'id') {
  return `${prefix}_${Math.random().toString(36).substr(2)}`
}