import request from '@/utils/request'

export function userData(data) {
  return request({
    url: '/auth/validate',
    method: 'post',
    data
  })
}

export function login(data) {
  return request({
    url: '/auth/login',
    method: 'POST',
    data
  })
}

export function logout(data) {
  return request({
    url: '/auth/logout',
    method: 'POST',
    data
  })
}

export function linktotp(data) {
  return request({
    url: '/user/linktotp',
    method: 'POST',
    data
  })
}

export function unlinktotp(data) {
  return request({
    url: '/user/unlinktotp',
    method: 'POST',
    data
  })
}

// 账号管理
export function backupList(params) {
  return request({
    url: '/backup/list',
    method: 'POST',
    data: params
  })
}
export function exportAccount(data) {
  return request({
    responseType: 'blob',
    url: '/backup/export',
    method: 'post',
    data
  })
}
export function exportCurrentAccount(data) {
  return request({
    responseType: 'blob',
    url: '/backup/current_page_export',
    method: 'post',
    data
  })
}
export function addRemark(data) {
  return request({
    url: '/info/update_data_by_pk',
    method: 'post',
    data
  })
}

// 统计
export function statis_backup(data) {
  return request({
    url: '/backup/statistics',
    method: 'post',
    data
  })
}

// relationShip互关数据
export function exportRelationship(data) {
  return request({
    url: '/friend/export',
    method: 'post',
    data,
    responseType: 'blob'
  })
}


// 手机号管理
export function phoneStatistics(data) {
  return request({
    url: '/phone/statistics',
    method: 'post',
    data
  })
}
export function phoneSearch(data) {
  return request({
    url: '/phone/search',
    method: 'post',
    data
  })
}

// ins采集
export function insSearch(data) {
  return request({
    url: '/instagram/search',
    method: 'post',
    data
  })
}
export function insList(data) {
  return request({
    url: '/instagram/list',
    method: 'post',
    data
  })
}
export function languages(data) {
  return request({
    url: '/instagram/languages',
    method: 'post',
    data
  })
}

/**
 * 群发
 */
export function createTask(data) {
  return request({
    url: '/task/create',
    method: 'post',
    data
  })
}
export function updateTask(data) {
  return request({
    url: '/task/updatestate',
    method: 'post',
    data
  })
}
export function taskDetail(data) {
  return request({
    url: '/task/detail',
    method: 'post',
    data
  })
}