/**
 * 群发任务状态
 */
export const groupTaskState = [
  'INIT',
  'START',
  'PROCESS',
  'FAILED',
  'sent',
  'CANCEL'
]

/**
 * 生成唯一key
 * @return {string}
 * @param length
 */
// export function uuid() {
//   return `${Math.random().toString(36).substr(2)}`
// }
export function uuid(length = 20) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUV0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}


export const scrollBottom = () => {
  setTimeout(() => {
    const ul = document.getElementsByClassName('chat-message-content')[0]
    if (ul) {
      ul.scrollTop = ul.scrollHeight + 220
    }
  }, 200)
}

/**
 * 获取格式化后的时间字符串
 * 默认为当前时间的yyyy-MM-dd hh:mm:ss
 * @param fmt
 * @param date
 * @return {string}
 */
export function getDateFormat(fmt = 'yyyy-MM-dd hh:mm:ss', date = new Date()) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // 如果时区是未知的，默认设置为 'Asia/Shanghai'
  if (timeZone === 'Etc/Unknown') {
    const options = {
      timeZone: 'Asia/Shanghai',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }
    const localeDateStr = new Intl.DateTimeFormat('en-US', options).format(date)
    // 将格式化后的字符串重新转为 Date 对象
    date = new Date(localeDateStr)
  }

  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      `${date.getFullYear()}`.substr(4 - RegExp.$1.length)
    )
  }

  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
      )
    }
  }
  return fmt
}

/**
 * 根据用户名生成背景色
 * @param str
 * @param alpha
 * @returns {string}
 */
export function getRandomColor(str) {
  if (!str) return '';
  let asciiSum = 0;
  for (let i = 0; i < str.length; i++) {
    asciiSum += str.charCodeAt(i);
  }

  // 计算基础颜色 (HSL 模式)
  const hue = Math.abs((Math.sin(asciiSum) * 360) + 20).toFixed(0);  // 色相 0-360
  const saturationStart = 60;  // 饱和度保持高
  const saturationEnd = 35;  // 饱和度保持高
  const lightnessStart = 70;  // 起始亮度
  const lightnessEnd = 90;    // 结束亮度，颜色更深

  // 生成线性渐变字符串
  return `linear-gradient(130deg, hsl(${hue}, ${saturationStart}%, ${lightnessStart}%) 0%, hsl(${hue}, ${saturationEnd}%, ${lightnessEnd}%) 100%)`;
}