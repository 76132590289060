import { getDateFormat } from '@/utils/tools'

export function convertToTmpMessage(content, type) {
  return {
    createtime: getDateFormat(),
    type,
    msg: content.text,
    id: content.id,
    status: 'INIT',
    title: 'send'
  }
}