import { createRouter, createWebHashHistory } from 'vue-router'
import beforeEach from '@/router/filters/beforeEach'
import Layout from '@/components/layout/index.vue'

export const routes = [
  {
    path: '/login',
    meta: {
      isHidden: true,
      keepAlive: false
    },
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/redirect/:path(.*)',
    name: 'Redirect',
    meta: {
      isHidden: true,
      keepAlive: false
    },
    component: () => import('@/views/redirect/index.vue')
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          title: '首页',
          icon: 'home'
        },
        component: () => import('@/views/dashboard/index.vue')
      },
      {
        path: '/account',
        name: 'Account',
        meta: {
          title: '账号管理',
          icon: 'UserFilled'
        },
        component: () => import('@/views/account/index.vue')
      },
      {
        path: '/relationship',
        name: 'Relationship',
        meta: {
          title: '互关查询',
          icon: 'Connection'
        },
        component: () => import('@/views/relationship/index.vue')
      },
      {
        path: '/phone',
        name: 'Phone',
        meta: {
          title: '手机号',
          icon: 'Cellphone'
        },
        component: () => import('@/views/phone/index.vue')
      },
      {
        path: '/insColl',
        name: 'InsCollection',
        meta: {
          title: '数据采集',
          icon: 'instagram'
        },
        component: () => import('@/views/insColl/index.vue')
      },
      {
        path: '/group',
        name: 'Group',
        meta: {
          title: '群发任务',
          icon: 'Group',
          level: 'system'
        },
        component: () => import('@/views/group/index.vue')
      },
      {
        path: '/groupDetail',
        name: 'GroupDetail',
        meta: {
          title: '群发详情',
          isHidden: true,
        },
        component: () => import('@/views/group/gsDetail.vue')
      },
      // {
      //   path: '/chat',
      //   name: 'Chat',
      //   meta: {
      //     title: '聊天会话',
      //     icon: 'chat',
      //     level: 'system'
      //   },
      //   component: () => import('@/views/chat/index.vue')
      // },
      {
        path: '/user/account',
        name: 'UserAccount',
        meta: {
          isHidden: true,
          keepAlive: false,
        },
        component: () => import('@/views/user/account/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes
})
router.beforeEach(beforeEach)

export default router