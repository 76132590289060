<template>
  <div class="main flex h-dvh overflow-x-hidden">
    <menu-com />
    <div class="main-content flex-1 flex flex-col w-0">
      <header>
        <header-com />
        <tag-com />
      </header>
      <div class="main-container m-4 flex-1">
        <router-view v-slot="{ Component, route }">
          <keep-alive :include="cachedTags">
            <component :is="getComponent(Component, route)" :key="route.fullPath" />
          </keep-alive>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderCom from '@/components/header/index.vue'
import TagCom from '@/components/tag/index.vue'
import MenuCom from '@/components/menu/index.vue'
import { useTagsStore } from '@/store/tags'
import { useRoute } from 'vue-router'
import { computed, h } from 'vue'

const wrapperMap = new Map()
export default {
  name: 'Layout',
  components: {
    HeaderCom,
    TagCom,
    MenuCom
  },
  computed: {
    cachedTags() {
      const store = useTagsStore()
      return store.cachedTags ? store.cachedTags : []
    }
  },
  setup() {
    const route = useRoute()
    const currentRoute = computed(() => route.path)
    const routeName = computed(() => route.name)
    console.log(route)

    const getComponent = (component, route) => {
      let wrapper
      if (component) {
        const wrapperName = route.fullPath
        if (wrapperMap.has(wrapperName)) {
          wrapper = wrapperMap.get(wrapperName)
        } else {
          wrapper = {
            name: wrapperName,
            render() {
              return h(component)
            }
          }
        }
        wrapperMap.set(wrapperName, wrapper)
      }
      return h(wrapper)
    }
    return {
      currentRoute,
      routeName,
      route,
      getComponent
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  position: absolute;
  top: 0;
  width: 100%;
  background: #fff;
  height: var(--itsop-header-sum-height);
  z-index: 100;
  box-sizing: border-box;
}
.main-content {
  background-color: #eff5ff;
  border-top-left-radius: 20px;
  overflow: hidden;
  position: relative;
}
.main-container {
  margin-top: calc(var(--itsop-header-sum-height) + 16px);
  overflow-y: auto;
}
.main {
  background-color: var(--itsop-menu-bg);
  .main-content {
    border-bottom-left-radius: 20px;
  }
}
</style>
