import { useTagsStore } from '@/store/tags'
import { useChatStore } from '@/store/chat'

export default function (to, from, next) {
  const tagsStore = useTagsStore()
  const chatStore = useChatStore()
  if (to.path === '/login') {
    tagsStore.clearVisitedTags()
    tagsStore.clearCachedTags()
    chatStore.RESET_ALL_CHAT_DATA()
  }
  if (to.meta.keepAlive !== false) {
    tagsStore.addVisitedTags(to)
    tagsStore.addCachedTags(to)
  }
  next()
}