import { defineStore } from 'pinia'
import { ref } from 'vue'
import { getRandomColor } from '@/utils/tools'
import { MESSAGE_TYPE } from '@/utils'
import { convertToTmpMessage } from '@/utils/wsMessageConvert'
import { getUploadUrl } from '@/api/chat'
import { useGlobalStore } from '@/store/global'

export const useChatStore = defineStore('chat', () => {
  const globalStore = useGlobalStore()

  const historyMsgList = ref(new Map())

  const currentDeviceId = ref(null)
  const deviceList = ref([])

  const setCurrentDeviceId = (deviceId) => {
    currentDeviceId.value = deviceId
  }
  const setDeviceList = (list) => {
    deviceList.value = list
  }

  const currentChatId = ref('')
  const currentChatInfo = ref({})
  const currentUserInfo = ref(null)

  const aPlaying = ref(false)
  const AUDIO_PLAYING = function (flag) {
    aPlaying.value = flag
  }


  const currentTalkList = ref([])
  const conversationList = ref([])

  const setCurrentChatId = (data) => {
    currentChatId.value = data.cid
    currentChatInfo.value = data
    // 消掉未读红点
    conversationList.value.find(item => {
      if (item.cid === data.cid && item.new_messages) {
        item.new_messages = 0
      }
    })
  }
  const setConversationList = list => {
    conversationList.value = [].concat(list)
  }

  // 更新左侧的会话列表
  const updateConversationStatus = (data) => {
    const index = conversationList.value.findIndex(item => {
      return item.id === data.id
    })
    if (index === 0) {
      // 已经是第一个了就只做更新
      data.profilePic = conversationList.value[index].profilePic
      conversationList.value[0] = data
    } else if (index > 0) {
      data.profilePic = conversationList.value[index].profilePic
      conversationList.value.splice(index, 1)
      conversationList.value.unshift(data)
    } else {
      if (!data.profilePic) {
        data.profilePic = getRandomColor(data.recipient)
      }
      conversationList.value.unshift(data)
    }
  }


  const setCurrentUserInfo = (userInfo) => {
    currentUserInfo.value = userInfo
  }

  const clearCurrentTalkList = () => {
    currentTalkList.value = []
  }
  const setCurrentTalkList = (talk, flag) => {
    currentTalkList.value.push(talk)
  }

  const updateMessageStatus = (message) => {
    const index = currentTalkList.value.findIndex(v => {
      return v.id === message.uuid
    })
    if (index >= 0) {
      currentTalkList.value[index] = {
        ...currentTalkList.value[index],
        id: message.id,
        status: message.status
      }
      const res = historyMsgList.value.get(currentChatId.value)
      res[index] = {
        ...res[index],
        id: message.id,
        status: message.status,
        message: message.attachments.message
      }
      historyMsgList.value.set(currentChatId.value, res)
    }
  }

  /**
   * 发送文件消息
   * @param commit
   * @param rootGetters
   * @param file
   * @param progress
   * @param userInfo
   * @param progress
   * @param userInfo
   * @param session
   * @returns {Promise}
   */
  const sendFile = ({ file, progress }) => {
    if (!file.gid) {
      file.gid = `progress_${new Date().valueOf()}`
    }
    const fileInfo = {
      id: file.gid,
      name: file.FileName,
      media: file.FileName.substring(file.FileName.lastIndexOf('.') + 1),
      text: file.url,
    }
    // fileInfo.size = formatSize(file.FileSize)
    const tmpMessage = convertToTmpMessage(
      fileInfo,
      file.FileType.startsWith('video/')
        ? MESSAGE_TYPE.Video : file.FileType.startsWith('audio/')
          ? MESSAGE_TYPE.Voice : MESSAGE_TYPE.File,
    )
    tmpMessage.progress = progress
    const formData = new FormData()
    formData.append('file', file.file)
    getUploadUrl(formData)
      .then(data => {
        if (data.data.url) {
          tmpMessage.message = data.data.url
          if (globalStore.socket) {
            globalStore.socket.sendMessage({
              type: 'sendMessage',
              data: {
                message: tmpMessage.message,
                type: file.FileType.startsWith('video/')
                  ? MESSAGE_TYPE.Video : file.FileType.startsWith('audio/')
                    ? MESSAGE_TYPE.Voice : MESSAGE_TYPE.File,
                uuid: file.gid,
                conversationId: currentChatId.value
              }
            })
          }
        }
      })
      .catch(() => {
        tmpMessage.sendMessageStatus = -1
      })
    setCurrentTalkList(tmpMessage)
  }
  /**
   * 发送图片消息
   * @param commit
   * @param rootState
   * @param rootGetters
   * @param content
   * @returns {Promise<void>}
   */
  function sendImage(content) {
    const tmpMessage = convertToTmpMessage(
      content,
      MESSAGE_TYPE.Image
    )
    // 发起http请求,发送图片
    const _http = blob => {
      const formData = new FormData()
      formData.append('file', blob)
      return getUploadUrl(formData)
        .then(data => {
          if (data.data.url) {
            tmpMessage.message = data.data.url
            if (globalStore.socket) {
              globalStore.socket.sendMessage({
                type: 'sendMessage',
                data: {
                  message: tmpMessage.message,
                  type: MESSAGE_TYPE.Image,
                  uuid: content.id,
                  conversationId: currentChatId.value
                }
              })
            }
          }
        })
        .catch(() => {
          tmpMessage.sendMessageStatus = -1
        })
    }
    setCurrentTalkList(tmpMessage)
    // base64 to blob
    // 兼容不支持fetch的移动端浏览器
    return new Promise((resolve, reject) => {
      try {
        const meta = content.text.split(',')
        const mime = meta[0].match(/:(.*?);/)[1]
        const bytes = window.atob(content.text.split(',')[1])
        const ab = new ArrayBuffer(bytes.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < bytes.length; i++) {
          ia[i] = bytes.charCodeAt(i)
        }
        const blob = new Blob([ab], { type: mime })
        resolve(blob)
      } catch (err) {
        reject(err)
      }
    })
      .then(_http)
      .catch(err => {
        console.log('网络出错', err)
      })
  }

  function RESET_ALL_CHAT_DATA() {
    currentChatId.value = ''
    currentChatInfo.value = {}
    currentUserInfo.value = null
    aPlaying.value = false
    currentTalkList.value = []
    conversationList.value = []

    historyMsgList.value = []
    currentDeviceId.value = null
    deviceList.value = []
  }

  // 插入到indexDB
  const push2Local = (data, flag) => {
    if (historyMsgList.value.has(currentChatId.value)) {
      let newMessageList = []
      const res = historyMsgList.value.get(currentChatId.value)
      if (flag !== 'unshift') {
        let len = 0
        for (let i = data.length - 1; i >= 0; i--) {
          if (res[res.length - 1].id === data[i].id) {
            len = data.length - 1 - i
          }
        }
        if (len > 0) {
          newMessageList = res.concat(data.slice(-len))
          historyMsgList.value.set(currentChatId.value, newMessageList)
        }
      } else {
        newMessageList = data.concat(res)
        historyMsgList.value.set(currentChatId.value, newMessageList)
      }
    } else {
      historyMsgList.value.set(currentChatId.value, data)
    }
  }

  return {
    historyMsgList,
    currentDeviceId,
    deviceList,
    setCurrentDeviceId,
    setDeviceList,

    currentChatId,
    conversationList,
    currentUserInfo,
    currentTalkList,
    currentChatInfo,
    aPlaying,

    setCurrentChatId,
    setConversationList,
    updateConversationStatus,
    setCurrentUserInfo,
    setCurrentTalkList,
    clearCurrentTalkList,
    updateMessageStatus,
    AUDIO_PLAYING,
    RESET_ALL_CHAT_DATA,

    push2Local,
    sendImage,
    sendFile,
  }
})